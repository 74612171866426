import {arr2tree} from "@/utils/ZUtils";

export interface ISkuType {
    id: number,
    weight: number //重量
    num: number //数量
    declare: number // 报价
    sku: string //sku
    cn: string,
    en: string
    usCode: string
    material: string //材质
    useage: string //用途
    brand: string // 品牌
    model: string //型号
    origin: string //原产地
    pic: string
    specAddPirce: any
    goodsId?: number
}


export interface IBoxType{
    id: number
    long: number
    wide: number
    high: number
    weight: number
    num: number
    productDtos: ISkuType[]
}

export interface ITotalData{
    weight?: number,    // 总重
    quantity?: number,  // 总件
    declare?: number    // 申报总价
    box?: number        // 总箱
}

export const skuItem:ISkuType = {
    id: 0,
    weight: 0,
    num: 1,
    declare: 0,
    sku: '',
    cn: '',
    en: '',
    usCode: '',
    material: '',
    useage: '',
    brand: '',
    model: '',
    origin: 'CN',
    pic: '',
    specAddPirce: '',
    goodsId: 0
}
export const boxItem:IBoxType = {
    id: 0,
    long: 0,
    wide: 0,
    high: 0,
    weight: 1,
    num: 1,
    productDtos: [
        skuItem
    ]
}

export const json2arr = (values: any) => {
    const keyArray = Object.keys(values)?.filter((keys: string) => keys.includes('-'))
    const boxKeyArray: string[] = []
    const skuKeyArray: string[] = []
    // box 和 sku分组处理
    keyArray.forEach((key: string) => {
        if (!key.replace('-', '+').includes('-')) {
            boxKeyArray.push(key)
        } else {
            skuKeyArray.push(key)
        }
    })

    // 箱属性
    const boxMap = boxKeyArray.reduce((result: any, i: string) => {
        const [key, value] = i?.split('-') ?? []
        if (!result[key]) {
            result[key] = {
                [value]: values[i],
                'id': Number(key)
            }
        } else {
            result[key][value] = values[i]
        }
        return result
    }, {})
    // skuItem 属性
    const skuMap = skuKeyArray.reduce((result: any, i: string) => {
        const [key, value, subKey] = i?.split('-') ?? []
        const k = `${key}-${subKey}`
        if (Object.keys(skuItem).includes(value) && values[i]) {
            if (!result[k]) {
                result[k] = {
                    [value]: values[i],
                    'id': Number(subKey),
                    'parentId': Number(key),
                    'box': Number(values[`${key}-num`])
                }
            } else {
                result[k][value] = values[i]
            }
        }
        return result
    }, {})
    return [...Object.values(boxMap), ...Object.values(skuMap)]
}

export const getBoxDtoList = (values: any) => arr2tree(json2arr(values))


//获取总数据
export const getTotalData = (params: any) => {
    const json: any = json2arr(params).reduce((result: any, i: any) => {
        if (!i.hasOwnProperty('parentId')) {
            result.weight += Number(i.weight || 0) * Number(i.num || 1)
            result.box += Number(i.num || 0)
        } else {
            result.quantity += Number(i.num || 0) * Number(i.box || 0)
        }
        return result
    }, {
        weight: 0,
        quantity: 0,
        box: 0
    })
    return json || {
        weight: 0,
        quantity: 0,
        box: 0
    }
}

// box转fields
export const getFields = (e: any) => {
    return e.reduce((result: any, i: IBoxType) => {
        i?.productDtos && i?.productDtos?.forEach?.((sub: ISkuType, subIndex: number) => Object.keys(sub)?.forEach((j: string) => {
            result[`${i.id}-${j}-${subIndex}`] = sub[j]
            result[`${i.id}-id-${subIndex}`] = subIndex
        }))
        Object.keys(i)?.forEach((key: string) => {
            if (key !== 'productDtos') {
                result[`${i.id}-${key}`] = i[key]
            }
        })
        return result
    }, {})
}

// 获取实重
export const getSubWeight = (sub:ISkuType[]) => {
    const total: number = sub?.reduce((result: number,i: ISkuType) => {
        result += Number(i?.weight??0) * Number(i?.num??0)
        return result
    },0)
    return total
}

// 校验箱内sku重量
export const validSku = (box: IBoxType[]) => {
    return box.reduce((result: any,i: any,index: number) =>{
        const weight: any = getSubWeight(i.productDtos)?.toFixed(1)
        if (Number(i.weight) < weight) {
            result[index + 1] = {
                status: true,
                box: index + 1,
                weight
            }
        }
        return result
    },{})
}

